import { hook } from '@/modules/cms/internal'

/** @type {import('./config').CMSModuleConfig} */
const config = {
  searchUrl: null,
}

export default (_, inject) => {
  inject('cms', {
    config: Object.assign(config, {"searchUrl":"?search={search_term_string}"}),
    hook,
  })
}